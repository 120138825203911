import '../styles/ArtSquaresStyles.css'
import ArtSquare from './ArtSquare'
import { FaPaintBrush } from "react-icons/fa";
import paintbrush from "../assets/paintbrush.cur"
import { useRef } from 'react';
export default function ArtSquares() {
    const showSquares = () => {
        let bracket = 0;
        let squares = [];
        for (let i = 0; i < 15; i++) {
            squares.push(<ArtSquare bracket={bracket} />);
            bracket++;
            if (bracket > 2) {
                bracket = 0;
            }

        }
        return squares
    }
    return (
        <div className="art-squares__container" style={{ cursor: `url("${paintbrush}"), auto` }}>
            {showSquares()}
        </div>
    )
}